import React from "react";
import BookInfo from "./BookInfo";
import ImageComponent from "./ImageComponent";
import Price from "./Price";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const DisplayBook = ({ book }) => {
  const openWhatsApp = (message) => {
    const phoneNumber = "+917842631444"; // Replace with your WhatsApp number
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  return (
    <div className="flex flex-col items-center border border-gray-300 p-3 rounded-lg bg-gray-100 shadow-md transition-all duration-300 m-2
        min-w-[120px] max-w-[140px] sm:min-w-[150px] sm:max-w-[180px] md:min-w-[180px] md:max-w-[220px] lg:min-w-[200px] lg:max-w-[240px] xl:min-w-[220px] xl:max-w-[260px]">

      <ImageComponent imageUrl={book.imageUrl} conditions={book.condition}/>
      <BookInfo isbn={book.isbn} title={book.title} author={book.author} />
      <Price lendingPricePerDay={book.lendingPricePerDay} sellingPrice={book.sellingPrice} />
      
      <div className="flex flex-col items-start w-full mt-2 space-y-2 lg:space-y-3 xl:space-y-4">
        <div className="flex items-center justify-between w-full">
          <span className="text-sm my-1 sm:my-0.5 sm:text-2xs lg:text-sm">Borrow</span>
          <button
            className="bg-gray-400 text-white py-1 px-1 rounded-md flex items-center justify-center hover:bg-blue-600 transition
              sm:py-1 sm:px-2 "
            onClick={() => openWhatsApp(`I want to borrow the book titled: ${book.title} `)}
          >
            <FontAwesomeIcon icon={faWhatsapp}  className="sm:py-0 sm:px-0"/>
          </button>
        </div>
        <div className="flex items-center justify-between w-full">
          <span className="text-sm my-1 sm:my-0.5 sm:text-2xs lg:text-sm">Buy</span>
          <button
            className="bg-gray-400 text-white py-1 px-1 rounded-md flex items-center justify-center hover:bg-green-600 transition
              sm:py-1 sm:px-2 "
            onClick={() => openWhatsApp(`I want to buy the book titled: ${book.title} and Author:${book.author}`)}
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisplayBook;
