import {useState,useEffect} from "react";


const Genre = ({ selectedGenres, onSelect, toggleSidebar }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    fetch('https://jingus.in/book_metadata/current_snapshot.json')
      .then(response => response.json())
      .then(data => {
        const genreArray = [...new Set(Object.keys(data).map(key => data[key].cy))];
        setGenres(genreArray);
      })
      .catch(error => console.error('Error fetching the book data:', error));
  }, []);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      onSelect((prev) => [...prev, value]);
    } else {
      onSelect((prev) => prev.filter((genre) => genre !== value));
    }
    toggleSidebar();
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className=" px-4 py-3">
      <h3 className="-mx-2 -my-3 flow-root">
        <button
          type="button"
          className="flex w-full items-center justify-between px-2 py-3 text-gray-400 hover:text-gray-500"
          aria-controls="filter-section-mobile-2"
          aria-expanded={isExpanded}
          onClick={toggleExpand}
        >
          <span className="font-medium text-white">Genre</span>
          <span className="ml-6 flex items-center">
            {!isExpanded ? (
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
              </svg>
            ) : (
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5h-10.5A.75.75 0 014 10z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </span>
        </button>
      </h3>

      {isExpanded && (
        <div className="pt-4 pb-2 pr-3">
          {genres.map((genre, index) => (
            <div key={index} className="flex items-center pr-3">
              <input
                id={`genre-${genre}`}
                name="genre"
                type="checkbox"
                value={genre}
                checked={selectedGenres.includes(genre)}
                onChange={handleChange}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label
                htmlFor={`genre-${genre}`}
                className="ml-3 text-sm text-white m-1"
              >
                {genre}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Genre;