import React, { useState } from 'react';
import FilterComponent from './FilterComponent';
import WhatsAppButton from '../WhatsAppButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown,faAngleUp } from '@fortawesome/free-solid-svg-icons';

const SidebarMenu = ({
  selectedAges,
  onAgeSelect,
  selectedGenres,
  onGenreSelect,
  selectedConditions,
  onConditionSelect,
}) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className="relative">
      <button
        className=" text-white rounded-lg p-2 cursor-pointer text-lg text-center sm:text-xl sm:p-1"
        onClick={toggleSidebar}
      >
        <span className='pr-2'>Filter</span>
        <FontAwesomeIcon icon={faAngleDown} />
      </button>

      {isSidebarVisible && (
        <div className="fixed  top-28 left-0 w-44 h-[calc(100%-8rem)] bg-customGray text-white shadow-lg z-50 overflow-y-auto flex flex-col box-border justify-between sm:inline sm:justify-center sm:mt-6 sm:top-20 sm:w-auto  sm:h-[calc(100%-5rem)]">
          <FilterComponent
            selectedAges={selectedAges}
            onAgeSelect={onAgeSelect}
            selectedGenres={selectedGenres}
            onGenreSelect={onGenreSelect}
            toggleSidebar={toggleSidebar}
            onConditionSelect={onConditionSelect}
            selectedConditions={selectedConditions}
          />
        </div>
      )}

      <WhatsAppButton />
    </div>
  );
};

export default SidebarMenu;
