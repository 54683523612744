import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppButton = () => {
  const phoneNumber = '+917842631444'; // Replace with your WhatsApp number

  return (
    <a 
      href={`https://wa.me/${phoneNumber}`} 
      target="_blank" 
      rel="noopener noreferrer"
      className="whatsapp-icon"
    >
      <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      <span style={{ marginLeft: '10px', marginRight: '10px' }}>+91 78426 31444</span>
    </a>
  );
};

export default WhatsAppButton;




