import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ searchTerm, onSearch }) => (
  <div className="flex items-center justify-between bg-white p-2.5 border-b border-gray-300 fixed top-0 left-0 right-0 z-50 shadow-sm max-w-full overflow-hidden h-16 sm:h-12">
    <img 
      src="jingus-logo.png" 
      alt="Logo" 
      className="w-36 h-auto object-contain mr-5 sm:w-28"
    />
    <input
      type="text"
      value={searchTerm}
      onChange={(e) => onSearch(e.target.value)}
      placeholder="Search by title or author"
      className="flex-1 p-2 text-sm border border-gray-300 rounded-md text-gray-700 min-w-[300px] max-w-[600px] mr-5 sm:text-xs sm:p-1"
    />
    {/* <div className="text-sm text-gray-700 whitespace-nowrap">
      <button>
        <FontAwesomeIcon icon={faCartShopping} className="text-xl sm:text-lg" />
      </button>
    </div> */}
  </div>
);

export default SearchBar;
