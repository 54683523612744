import { useInView } from 'react-intersection-observer';

const ImageComponent = ({ imageUrl, conditions }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className="relative w-24 h-20 mb-2 rounded-md overflow-hidden bg-gray-200 sm:w-20 sm:h-20 sm:mb-2"
    >
      {inView && (
        <>
          <img
            src={imageUrl}
            alt="Book Cover"
            className="w-full h-full object-cover"
          />
          {/* Overlay text outside the top-right corner */}
          <div className="absolute -top-2 -right-2 bg-red-400 text-white text-xs pl-1 pr-3 pt-2 rounded z-10">
            {conditions}
          </div>
        </>
      )}
    </div>
  );
};

export default ImageComponent;
