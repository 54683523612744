import AgeCategory from "./AgeCategory";
import Conditions from "./Conditions";
import Genre from "./Genre";

const FilterComponent = ({
  selectedAges,
  onAgeSelect,
  selectedGenres,
  onGenreSelect,
  toggleSidebar,
  onConditionSelect,
  selectedConditions,
}) => {
  return (
    <div>
      <AgeCategory
        selectedAges={selectedAges}
        onSelect={onAgeSelect}
        toggleSidebar={toggleSidebar}
      />
      <Genre
        selectedGenres={selectedGenres}
        onSelect={onGenreSelect}
        toggleSidebar={toggleSidebar}
      />
      <Conditions
        selectedConditions={selectedConditions}
        onSelect={onConditionSelect}
        toggleSidebar={toggleSidebar}
      />
    </div>
  );
};

export default FilterComponent;