import React, { useEffect, useState } from 'react';

import './styles.css'; // Import CSS file
import WhatsAppButton from './WhatsAppButton';
// import  DisplayBook  from "./Components/DisplayBook";
import SearchBar from './Components/SearchBar';
import SidebarMenu from './Components/SidebarMenu';
import BookGrid from './Components/BookGrid';







// App Component
const App = () => {
  const [books, setBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAges, setSelectedAges] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetch('https://jingus.in/book_metadata/current_snapshot.json')
      .then(response => response.json())
      .then(data => {
        const booksArray = Object.keys(data).map(key => ({
          isbn: data[key].in,
          title: data[key].te,
          sellingPrice: data[key].sp,
          lendingPricePerDay: data[key].lp,
          author: data[key].au,
          ageGroup: data[key].ag,
          genre: data[key].cy,
          condition: data[key].cn,          
          imageUrl: `https://jingus.in/isbn_images/medium/${data[key].in}.jpg`
        }));
        setBooks(booksArray);
        setFilteredBooks(booksArray);
        setLoading(false);
      })
      .catch(error => console.error('Error fetching the book data:', error));
  }, []);

  useEffect(() => {
    let updatedBooks = books;
    if (selectedAges.length > 0) {
      updatedBooks = updatedBooks.filter(book => selectedAges.includes(book.ageGroup));
    }
    if (selectedGenres.length > 0) {
      updatedBooks = updatedBooks.filter(book => selectedGenres.includes(book.genre));
    }
    if (selectedConditions.length > 0) {
      updatedBooks = updatedBooks.filter(book => selectedConditions.includes(book.condition));
    }
    if (searchTerm.length > 2) {
      updatedBooks = updatedBooks.filter(book => 
        (book.title && book.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (book.author && book.author.toLowerCase().includes(searchTerm.toLowerCase()))

      );
    }
    setFilteredBooks(updatedBooks);
  }, [selectedAges, selectedGenres, books, selectedConditions, searchTerm]);

  return (
    <div>
      <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      <div className="menu-bar">
      <SidebarMenu 
       selectedAges={selectedAges}
       onAgeSelect={setSelectedAges}
       selectedGenres={selectedGenres}
       onGenreSelect={setSelectedGenres}
       selectedConditions={selectedConditions}
       onConditionSelect={setSelectedConditions}
      />
      
      </div>
     
     <div style={{ 
        marginTop: '120px',
        display: 'flex', 
        flexWrap: 'wrap', 
        justifyContent: 'center', 
        overflowY: 'auto', 
        backgroundColor: '#f0f0f0' 
        
      }}>
        {loading ? (
          <p>Loading books...</p>
        ) : (
          <BookGrid books={filteredBooks} />
        )}
      </div>
      <WhatsAppButton />
    </div>
  );
};

export default App;
