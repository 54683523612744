import DisplayBook from "./DisplayBook";


const BookGrid = ({ books }) => (
  <div className="grid grid-cols-3 gap-2 px-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
    {books.map((book) => (
      <DisplayBook key={book.isbn} book={book} />
    ))}
  </div>
);

export default BookGrid;
