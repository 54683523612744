const Price = ({ lendingPricePerDay, sellingPrice }) => (
  <div className="text-center text-gray-600 sm:text-2xs lg:text-sm">
    {lendingPricePerDay && (
      <p className="text-sm my-1 sm:my-0.5 sm:text-2xs lg:text-sm">
        <strong>Lending:</strong> ₹{lendingPricePerDay} /day
      </p>
    )}
    {sellingPrice && (
      <p className="text-sm my-1 sm:my-0.5 sm:text-2xs lg:text-sm">
        <strong>Buy:</strong> ₹{sellingPrice}
      </p>
    )}
  </div>
);

export default Price;
