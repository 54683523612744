const BookInfo = ({ isbn, title, author }) => (
  <div className="text-center mb-2 text-gray-800 sm:mb-2 sm:text-sm">
    <h2 className="text-sm font-medium mb-2 sm:text-sm sm:mb-1">
      {title}
    </h2>
    <p className="text-sm mb-1 sm:mb-0.5 sm:text-sm">
      <strong>Author:</strong> {author}
    </p>
    <p className="text-sm sm:text-2xs sm:mb-0">
      <strong>ISBN:</strong> {isbn}
    </p>
  </div>
);

export default BookInfo;
